import { render, staticRenderFns } from "./MyAbout.vue?vue&type=template&id=6ab90281&scoped=true"
import script from "./MyAbout.vue?vue&type=script&lang=js"
export * from "./MyAbout.vue?vue&type=script&lang=js"
import style0 from "./MyAbout.vue?vue&type=style&index=0&id=6ab90281&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ab90281",
  null
  
)

export default component.exports